// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  baseUrl  : 'https://localhost:4200/',
  imgBaseUrl: 'https://www.storage.anudip.org/home/storage/',
  apiUrl   : 'https://icollabapi.anudip.org/',
  cmisLoginUrl : 'https://cmis3api.anudip.org/',
  gloginUrl: 'https://cmis3api.anudip.org/api/login/google/',
};

//For Stage server//
// export const environment = {
//   production: true,
//   baseUrl  : 'http://109.235.71.145/cmisv3/public/',
//   imgBaseUrl: 'http://109.235.71.145/cmisv3/public/',
//   apiUrl   : 'http://109.235.71.145/cmisv3/public/',
//   gloginUrl: 'http://cmis3api.anudip.org/api/login/google/',
// };

// export const environment = {
//   production: true,
//   baseUrl  : 'https://716a-43-252-248-107.in.ngrok.io/cmisv3/public/',
//   imgBaseUrl: 'https://716a-43-252-248-107.in.ngrok.io/cmisv3/public/',
//   apiUrl   : 'https://716a-43-252-248-107.in.ngrok.io/cmisv3/public/',
//   gloginUrl: 'http://cmis3api.anudip.org/api/login/google/',
// };



