import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class httpintc implements HttpInterceptor {
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        
        let currentUser = JSON.parse(localStorage.getItem('currentUser') || '{}');
        //console.log(currentUser.token);
        if (currentUser && currentUser.token) { 
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${currentUser.token}`
                }
            });
        }
        return next.handle(request).pipe(
            catchError(err => {
                if(err.status === 401) {
                    localStorage.removeItem('currentUser');
                    location.reload();
                }
              const error = err.error.message || err.statusText;
              return throwError(error);

            })
        )
    }
}
