import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./modules/auth/auth.module').then( m => m.AuthPageModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./modules/dashboard/dashboard.module').then( m => m.DashboardPageModule)
  },
  {
    path: 'tabs',
    loadChildren: () => import('./modules/tabs/tabs.module').then( m => m.TabsPageModule)
  },
  {
    path: 'add-assignment',
    loadChildren: () => import('./modules/content-curriculum/content-curriculum.module').then( m => m.ContentCurriculumPageModule)
  },
  {
    path: 'trainer',
    loadChildren: () => import('./modules/trainer/trainer.module').then( m => m.TrainerPageModule)
  },
  {
    path: 'content-dashboard',
    loadChildren: () => import('./modules/content-curriculam-dashboard/content-curriculam-dashboard.module').then( m => m.ContentCurriculamDashboardPageModule)
  },  {
    path: 'create-forum-card-category',
    loadChildren: () => import('./modules/create-forum-card-category/create-forum-card-category.module').then( m => m.CreateForumCardCategoryPageModule)
  },
  {
    path: 'list-forum-card-category',
    loadChildren: () => import('./modules/list-forum-card-category/list-forum-card-category.module').then( m => m.ListForumCardCategoryPageModule)
  },
  {
    path: 'create-behaviour-matrics',
    loadChildren: () => import('./modules/create-behaviour-matrics/create-behaviour-matrics.module').then( m => m.CreateBehaviourMatricsPageModule)
  },
  {
    path: 'list-behaviour-matrics',
    loadChildren: () => import('./modules/list-behaviour-matrics/list-behaviour-matrics.module').then( m => m.ListBehaviourMatricsPageModule)
  },
  {
    path: 'activity-assign',
    loadChildren: () => import('./modules/activity-assign/activity-assign.module').then( m => m.ActivityAssignPageModule)
  },
  {
    path: 'trainer-forum-card-view',
    loadChildren: () => import('./modules/trainer-forum-card-view/trainer-forum-card-view.module').then( m => m.TrainerForumCardViewPageModule)
  },
  {
    path: 'view-trainer-score-rule',
    loadChildren: () => import('./modules/view-trainer-score-rule/view-trainer-score-rule.module').then( m => m.ViewTrainerScoreRulePageModule)
  },
  {
    path: 'student-marks',
    loadChildren: () => import('./modules/student-marks/student-marks.module').then( m => m.StudentMarksPageModule)
  },
  {
    path: 'trainer-report-download',
    loadChildren: () => import('./modules/trainer-report-download/trainer-report-download.module').then( m => m.TrainerReportDownloadPageModule)
  },
  {
    path: 'trainer-facilitator-score',
    loadChildren: () => import('./modules/trainer-facilitator-score/trainer-facilitator-score.module').then( m => m.TrainerFacilitatorScorePageModule)
  }







];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
